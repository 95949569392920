import * as Types from '../../types.generated';

import { api } from 'libs';
module.hot?.accept();
export type ContractsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ContractsQuery = { __typename?: 'Query', contracts: Array<{ __typename?: 'Contract', id: string, name: any, type: Types.ContractType, documentId: any, undefinedTerm: boolean }> };

export type CertificationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CertificationsQuery = { __typename?: 'Query', certifications: Array<{ __typename?: 'Certification', id: string, name: any, showToInactive: boolean, documentId: any, contractId: string, contract: { __typename?: 'Contract', name: any } }> };

export type CustomFieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomFieldsQuery = { __typename?: 'Query', customFields: Array<{ __typename?: 'CustomField', id: string, name: any, type: any }> };

export type PositionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PositionsQuery = { __typename?: 'Query', positions: Array<{ __typename?: 'Position', id: string, name: any }> };

export type ContractQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ContractQuery = { __typename?: 'Query', contract?: { __typename?: 'Contract', id: string, name: any, type: Types.ContractType, undefinedTerm: boolean, fields: Array<any>, documents?: any, documentId: any, certifications: Array<{ __typename?: 'Certification', id: string, name: any, documentId: any }> } };

export type EmployeesQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.EmployeesInput>;
}>;


export type EmployeesQuery = { __typename?: 'Query', employees: Array<{ __typename?: 'Employee', id: string, idNumber: string, firstName: string, middleName: string, lastName: string, secondLastName: string, email: string, contract: { __typename?: 'Contract', name: any } }> };

export type EmployeeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type EmployeeQuery = { __typename?: 'Query', employee: { __typename?: 'Employee', id: string, fullName: string, firstName: string, middleName: string, lastName: string, secondLastName: string, idNumber: string, idType: string, dateBirth?: any, dateFamily?: any, personalEmail?: string, stateBirth?: string, address?: string, countryLocation: string, city?: string, state?: string, cellPhone?: string, phone?: string, civilStatus?: string, pensionFund?: string, eps?: string, accountNumber?: string, accountType?: string, accountBank?: string, arl?: string, hasHighSchool?: boolean, hasElementarySchool?: boolean, hasUniversity?: boolean, hasGraduateDegree?: boolean, hasMastersDegree?: boolean, hasDoctorateDegree?: boolean, whereStudy?: string, salary: any, salaryInLetters: string, bonus: any, bonusInLetters: string, fees: any, positionId: string, contractId: string, areaId: string, countryId: string, affidavit?: boolean, email: string, folderId?: string, userId?: string, active: boolean, dateStart: any, dateStartInText: string, dateEnd?: any, custom: any, deletedAt?: any, createdAt: any, updatedAt: any, subAreaId: string, celebrateBirthday: boolean, compensationFund: string, entranceExams: string, gender: string, severanceFund: string, currency: string, withdrawalReason: string, computerHardware: string, vacationInfo: { __typename?: 'EmployeeVacation', accumulated: number, requested: number, available: number }, position: { __typename?: 'Position', id: string, name: any }, contract: { __typename?: 'Contract', id: string, documentId: any, type: Types.ContractType, name: any } } };

export type GetAllEmployeesQueryVariables = Types.Exact<{
  active?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetAllEmployeesQuery = { __typename?: 'Query', employees: Array<{ __typename?: 'Employee', id: string, fullName: string, firstName: string, middleName: string, lastName: string, secondLastName: string, idNumber: string, idType: string, dateBirth?: any, dateFamily?: any, personalEmail?: string, stateBirth?: string, address?: string, city?: string, state?: string, cellPhone?: string, phone?: string, civilStatus?: string, pensionFund?: string, eps?: string, accountNumber?: string, accountType?: string, accountBank?: string, arl?: string, hasHighSchool?: boolean, hasElementarySchool?: boolean, hasUniversity?: boolean, hasGraduateDegree?: boolean, hasMastersDegree?: boolean, hasDoctorateDegree?: boolean, whereStudy?: string, salary: any, bonus: any, email: string, userId?: string, dateStart: any, dateEnd?: any, custom: any, subAreaId: string, celebrateBirthday: boolean, compensationFund: string, entranceExams: string, gender: string, severanceFund: string, currency: string, countryLocation: string, computerHardware: string, withdrawalReason: string, position: { __typename?: 'Position', id: string, name: any }, contract: { __typename?: 'Contract', id: string, name: any } }> };

export type SimpleSearchEmployeeQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']>;
  roles?: Types.InputMaybe<Array<Types.Role> | Types.Role>;
}>;


export type SimpleSearchEmployeeQuery = { __typename?: 'Query', searchEmployees: Array<{ __typename?: 'SearchEmployeeValue', employeeId: string, fullName: string, userId: string, email: string }> };

export type SearchEmployeeQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
}>;


export type SearchEmployeeQuery = { __typename?: 'Query', employees: Array<{ __typename?: 'Employee', id: string, idNumber: string, firstName: string, lastName: string, contract: { __typename?: 'Contract', id: string, name: any } }> };

export type SearchEmployeeWithCertificationsAvailablesQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
}>;


export type SearchEmployeeWithCertificationsAvailablesQuery = { __typename?: 'Query', employees: Array<{ __typename?: 'Employee', id: string, idNumber: string, firstName: string, lastName: string, certificationsAvailables: Array<{ __typename?: 'Certification', id: string, name: any }>, contract: { __typename?: 'Contract', id: string, name: any } }> };

export type GetEmployeeEditableFieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEmployeeEditableFieldsQuery = { __typename?: 'Query', setting?: { __typename?: 'Setting', value?: any } };

export type GetUserAccountQueryVariables = Types.Exact<{
  accountId: Types.Scalars['EmailAddress'];
}>;


export type GetUserAccountQuery = { __typename?: 'Query', getUserAccount?: { __typename?: 'UserAccount', id: string, primaryEmail: any } };

export type PutEmployeeEditableFieldsMutationVariables = Types.Exact<{
  value: Types.Scalars['JSON'];
}>;


export type PutEmployeeEditableFieldsMutation = { __typename?: 'Mutation', putSetting?: { __typename?: 'Setting', value?: any } };

export type SoftDeleteContractMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SoftDeleteContractMutation = { __typename?: 'Mutation', softDeleteContract?: any };

export type CreateContractMutationVariables = Types.Exact<{
  input: Types.CreateContractInput;
}>;


export type CreateContractMutation = { __typename?: 'Mutation', createContract: { __typename?: 'Contract', id: string, name: any, documentId: any } };

export type DeleteCertificationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteCertificationMutation = { __typename?: 'Mutation', deleteCertification?: any };

export type CreateCertificationMutationVariables = Types.Exact<{
  input: Types.CreateCertificationInput;
}>;


export type CreateCertificationMutation = { __typename?: 'Mutation', createCertification: { __typename?: 'Certification', id: string, name: any, documentId: any } };

export type EditCertificationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  data: Types.EditCertificationData;
}>;


export type EditCertificationMutation = { __typename?: 'Mutation', editCertification: { __typename?: 'Certification', id: string, name: any, contractId: string, documentId: any } };

export type CreateEmployeeMutationVariables = Types.Exact<{
  input: Types.CreateEmployeeInput;
}>;


export type CreateEmployeeMutation = { __typename?: 'Mutation', createEmployee: { __typename?: 'Employee', id: string, idNumber: string, idType: string, firstName: string, lastName: string, contract: { __typename?: 'Contract', id: string, name: any } } };

export type EditEmployeeMutationVariables = Types.Exact<{
  input: Types.EditEmployeeInput;
}>;


export type EditEmployeeMutation = { __typename?: 'Mutation', editEmployee: { __typename?: 'Employee', id: string, idNumber: string, idType: string, firstName: string, lastName: string, contract: { __typename?: 'Contract', id: string, name: any } } };

export type DeleteCustomFieldMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteCustomFieldMutation = { __typename?: 'Mutation', deleteCustomField?: any };

export type CreateCustomFieldMutationVariables = Types.Exact<{
  input: Types.CreateCustomFieldInput;
}>;


export type CreateCustomFieldMutation = { __typename?: 'Mutation', createCustomField: { __typename?: 'CustomField', id: string, name: any, type: any } };

export type BuildDocumentMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.BuildDocumentInput>;
}>;


export type BuildDocumentMutation = { __typename?: 'Mutation', buildDocument: { __typename?: 'BuildDocumentResponse', data: string } };

export type ToggleEmployeeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  active: Types.Scalars['Boolean'];
}>;


export type ToggleEmployeeMutation = { __typename?: 'Mutation', toggleEmployee?: any };

export type TerminateEmployeeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  dateEnd: Types.Scalars['Date'];
  withdrawalReason?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type TerminateEmployeeMutation = { __typename?: 'Mutation', terminateEmployee?: any };

export type UpdateContractMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  data: Types.UpdateContractData;
}>;


export type UpdateContractMutation = { __typename?: 'Mutation', updateContract?: any };

export type UpdateAmountsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.AmountsEmployeeInput;
}>;


export type UpdateAmountsMutation = { __typename?: 'Mutation', updateAmountsEmployee?: any };

export type CreatePositionMutationVariables = Types.Exact<{
  payload: Types.CreatePositionPayload;
}>;


export type CreatePositionMutation = { __typename?: 'Mutation', createPosition: { __typename?: 'Position', id: string, name: any } };

export type UpdatePositionMutationVariables = Types.Exact<{
  payload: Types.UpdatePositionPayload;
}>;


export type UpdatePositionMutation = { __typename?: 'Mutation', updatePosition: { __typename?: 'Position', id: string, name: any } };

export type SoftDeletePositionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SoftDeletePositionMutation = { __typename?: 'Mutation', softDeletePosition?: any };


export const ContractsDocument = `
    query Contracts {
  contracts {
    id
    name
    type
    documentId
    undefinedTerm
  }
}
    `;
export const CertificationsDocument = `
    query Certifications {
  certifications {
    id
    name
    showToInactive
    documentId
    contractId
    contract {
      name
    }
  }
}
    `;
export const CustomFieldsDocument = `
    query CustomFields {
  customFields {
    id
    name
    type
  }
}
    `;
export const PositionsDocument = `
    query Positions {
  positions {
    id
    name
  }
}
    `;
export const ContractDocument = `
    query Contract($id: ID!) {
  contract(id: $id) {
    id
    name
    type
    undefinedTerm
    fields
    documents
    documentId
    certifications {
      id
      name
      documentId
    }
  }
}
    `;
export const EmployeesDocument = `
    query Employees($input: EmployeesInput) {
  employees(input: $input) {
    id
    idNumber
    firstName
    middleName
    lastName
    secondLastName
    email
    contract {
      name
    }
  }
}
    `;
export const EmployeeDocument = `
    query Employee($id: ID!) {
  employee(id: $id) {
    id
    fullName
    firstName
    middleName
    lastName
    secondLastName
    idNumber
    idType
    dateBirth
    dateFamily
    personalEmail
    stateBirth
    address
    countryLocation
    city
    state
    cellPhone
    phone
    civilStatus
    pensionFund
    eps
    accountNumber
    accountType
    accountBank
    arl
    hasHighSchool
    hasElementarySchool
    hasUniversity
    hasGraduateDegree
    hasMastersDegree
    hasDoctorateDegree
    whereStudy
    salary
    salaryInLetters
    bonus
    bonusInLetters
    fees
    positionId
    contractId
    areaId
    countryId
    affidavit
    email
    folderId
    userId
    active
    dateStart
    dateStartInText
    dateEnd
    custom
    deletedAt
    createdAt
    updatedAt
    vacationInfo {
      accumulated
      requested
      available
    }
    position {
      id
      name
    }
    contract {
      id
      documentId
      type
      name
    }
    subAreaId
    celebrateBirthday
    compensationFund
    entranceExams
    gender
    severanceFund
    currency
    withdrawalReason
    computerHardware
  }
}
    `;
export const GetAllEmployeesDocument = `
    query GetAllEmployees($active: Boolean) {
  employees(input: {active: $active, take: 1000}) {
    id
    fullName
    firstName
    middleName
    lastName
    secondLastName
    idNumber
    idType
    dateBirth
    dateFamily
    personalEmail
    stateBirth
    address
    city
    state
    cellPhone
    phone
    civilStatus
    pensionFund
    eps
    accountNumber
    accountType
    accountBank
    arl
    hasHighSchool
    hasElementarySchool
    hasUniversity
    hasGraduateDegree
    hasMastersDegree
    hasDoctorateDegree
    whereStudy
    salary
    bonus
    email
    userId
    dateStart
    dateEnd
    custom
    position {
      id
      name
    }
    contract {
      id
      name
    }
    subAreaId
    celebrateBirthday
    compensationFund
    entranceExams
    gender
    severanceFund
    currency
    countryLocation
    computerHardware
    withdrawalReason
  }
}
    `;
export const SimpleSearchEmployeeDocument = `
    query SimpleSearchEmployee($search: String, $roles: [Role!]) {
  searchEmployees(payload: {search: $search, roles: $roles}) {
    employeeId
    fullName
    userId
    email
  }
}
    `;
export const SearchEmployeeDocument = `
    query SearchEmployee($search: String!) {
  employees(input: {search: $search, take: 5}) {
    id
    idNumber
    firstName
    lastName
    contract {
      id
      name
    }
  }
}
    `;
export const SearchEmployeeWithCertificationsAvailablesDocument = `
    query SearchEmployeeWithCertificationsAvailables($search: String!) {
  employees(input: {search: $search, take: 5}) {
    id
    idNumber
    firstName
    lastName
    certificationsAvailables {
      id
      name
    }
    contract {
      id
      name
    }
  }
}
    `;
export const GetEmployeeEditableFieldsDocument = `
    query GetEmployeeEditableFields {
  setting(key: "employees-editable-fields") {
    value
  }
}
    `;
export const GetUserAccountDocument = `
    query GetUserAccount($accountId: EmailAddress!) {
  getUserAccount(accountId: $accountId) {
    id
    primaryEmail
  }
}
    `;
export const PutEmployeeEditableFieldsDocument = `
    mutation PutEmployeeEditableFields($value: JSON!) {
  putSetting(key: "employees-editable-fields", value: $value) {
    value
  }
}
    `;
export const SoftDeleteContractDocument = `
    mutation SoftDeleteContract($id: ID!) {
  softDeleteContract(id: $id)
}
    `;
export const CreateContractDocument = `
    mutation CreateContract($input: CreateContractInput!) {
  createContract(input: $input) {
    id
    name
    documentId
  }
}
    `;
export const DeleteCertificationDocument = `
    mutation DeleteCertification($id: ID!) {
  deleteCertification(id: $id)
}
    `;
export const CreateCertificationDocument = `
    mutation CreateCertification($input: CreateCertificationInput!) {
  createCertification(input: $input) {
    id
    name
    documentId
  }
}
    `;
export const EditCertificationDocument = `
    mutation EditCertification($id: ID!, $data: EditCertificationData!) {
  editCertification(id: $id, data: $data) {
    id
    name
    contractId
    documentId
  }
}
    `;
export const CreateEmployeeDocument = `
    mutation CreateEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    id
    idNumber
    idType
    firstName
    lastName
    contract {
      id
      name
    }
  }
}
    `;
export const EditEmployeeDocument = `
    mutation EditEmployee($input: EditEmployeeInput!) {
  editEmployee(input: $input) {
    id
    idNumber
    idType
    firstName
    lastName
    contract {
      id
      name
    }
  }
}
    `;
export const DeleteCustomFieldDocument = `
    mutation DeleteCustomField($id: ID!) {
  deleteCustomField(id: $id)
}
    `;
export const CreateCustomFieldDocument = `
    mutation CreateCustomField($input: CreateCustomFieldInput!) {
  createCustomField(input: $input) {
    id
    name
    type
  }
}
    `;
export const BuildDocumentDocument = `
    mutation BuildDocument($input: BuildDocumentInput) {
  buildDocument(input: $input) {
    data
  }
}
    `;
export const ToggleEmployeeDocument = `
    mutation ToggleEmployee($id: ID!, $active: Boolean!) {
  toggleEmployee(id: $id, active: $active)
}
    `;
export const TerminateEmployeeDocument = `
    mutation TerminateEmployee($id: ID!, $dateEnd: Date!, $withdrawalReason: String) {
  terminateEmployee(
    id: $id
    dateEnd: $dateEnd
    withdrawalReason: $withdrawalReason
  )
}
    `;
export const UpdateContractDocument = `
    mutation UpdateContract($id: ID!, $data: UpdateContractData!) {
  updateContract(id: $id, data: $data)
}
    `;
export const UpdateAmountsDocument = `
    mutation UpdateAmounts($id: ID!, $input: AmountsEmployeeInput!) {
  updateAmountsEmployee(id: $id, input: $input)
}
    `;
export const CreatePositionDocument = `
    mutation CreatePosition($payload: CreatePositionPayload!) {
  createPosition(payload: $payload) {
    id
    name
  }
}
    `;
export const UpdatePositionDocument = `
    mutation UpdatePosition($payload: UpdatePositionPayload!) {
  updatePosition(payload: $payload) {
    id
    name
  }
}
    `;
export const SoftDeletePositionDocument = `
    mutation SoftDeletePosition($id: ID!) {
  softDeletePosition(id: $id)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Contracts: build.query<ContractsQuery, ContractsQueryVariables | void>({
      query: (variables) => ({ document: ContractsDocument, variables })
    }),
    Certifications: build.query<CertificationsQuery, CertificationsQueryVariables | void>({
      query: (variables) => ({ document: CertificationsDocument, variables })
    }),
    CustomFields: build.query<CustomFieldsQuery, CustomFieldsQueryVariables | void>({
      query: (variables) => ({ document: CustomFieldsDocument, variables })
    }),
    Positions: build.query<PositionsQuery, PositionsQueryVariables | void>({
      query: (variables) => ({ document: PositionsDocument, variables })
    }),
    Contract: build.query<ContractQuery, ContractQueryVariables>({
      query: (variables) => ({ document: ContractDocument, variables })
    }),
    Employees: build.query<EmployeesQuery, EmployeesQueryVariables | void>({
      query: (variables) => ({ document: EmployeesDocument, variables })
    }),
    Employee: build.query<EmployeeQuery, EmployeeQueryVariables>({
      query: (variables) => ({ document: EmployeeDocument, variables })
    }),
    GetAllEmployees: build.query<GetAllEmployeesQuery, GetAllEmployeesQueryVariables | void>({
      query: (variables) => ({ document: GetAllEmployeesDocument, variables })
    }),
    SimpleSearchEmployee: build.query<SimpleSearchEmployeeQuery, SimpleSearchEmployeeQueryVariables | void>({
      query: (variables) => ({ document: SimpleSearchEmployeeDocument, variables })
    }),
    SearchEmployee: build.query<SearchEmployeeQuery, SearchEmployeeQueryVariables>({
      query: (variables) => ({ document: SearchEmployeeDocument, variables })
    }),
    SearchEmployeeWithCertificationsAvailables: build.query<SearchEmployeeWithCertificationsAvailablesQuery, SearchEmployeeWithCertificationsAvailablesQueryVariables>({
      query: (variables) => ({ document: SearchEmployeeWithCertificationsAvailablesDocument, variables })
    }),
    GetEmployeeEditableFields: build.query<GetEmployeeEditableFieldsQuery, GetEmployeeEditableFieldsQueryVariables | void>({
      query: (variables) => ({ document: GetEmployeeEditableFieldsDocument, variables })
    }),
    GetUserAccount: build.query<GetUserAccountQuery, GetUserAccountQueryVariables>({
      query: (variables) => ({ document: GetUserAccountDocument, variables })
    }),
    PutEmployeeEditableFields: build.mutation<PutEmployeeEditableFieldsMutation, PutEmployeeEditableFieldsMutationVariables>({
      query: (variables) => ({ document: PutEmployeeEditableFieldsDocument, variables })
    }),
    SoftDeleteContract: build.mutation<SoftDeleteContractMutation, SoftDeleteContractMutationVariables>({
      query: (variables) => ({ document: SoftDeleteContractDocument, variables })
    }),
    CreateContract: build.mutation<CreateContractMutation, CreateContractMutationVariables>({
      query: (variables) => ({ document: CreateContractDocument, variables })
    }),
    DeleteCertification: build.mutation<DeleteCertificationMutation, DeleteCertificationMutationVariables>({
      query: (variables) => ({ document: DeleteCertificationDocument, variables })
    }),
    CreateCertification: build.mutation<CreateCertificationMutation, CreateCertificationMutationVariables>({
      query: (variables) => ({ document: CreateCertificationDocument, variables })
    }),
    EditCertification: build.mutation<EditCertificationMutation, EditCertificationMutationVariables>({
      query: (variables) => ({ document: EditCertificationDocument, variables })
    }),
    CreateEmployee: build.mutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>({
      query: (variables) => ({ document: CreateEmployeeDocument, variables })
    }),
    EditEmployee: build.mutation<EditEmployeeMutation, EditEmployeeMutationVariables>({
      query: (variables) => ({ document: EditEmployeeDocument, variables })
    }),
    DeleteCustomField: build.mutation<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables>({
      query: (variables) => ({ document: DeleteCustomFieldDocument, variables })
    }),
    CreateCustomField: build.mutation<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>({
      query: (variables) => ({ document: CreateCustomFieldDocument, variables })
    }),
    BuildDocument: build.mutation<BuildDocumentMutation, BuildDocumentMutationVariables | void>({
      query: (variables) => ({ document: BuildDocumentDocument, variables })
    }),
    ToggleEmployee: build.mutation<ToggleEmployeeMutation, ToggleEmployeeMutationVariables>({
      query: (variables) => ({ document: ToggleEmployeeDocument, variables })
    }),
    TerminateEmployee: build.mutation<TerminateEmployeeMutation, TerminateEmployeeMutationVariables>({
      query: (variables) => ({ document: TerminateEmployeeDocument, variables })
    }),
    UpdateContract: build.mutation<UpdateContractMutation, UpdateContractMutationVariables>({
      query: (variables) => ({ document: UpdateContractDocument, variables })
    }),
    UpdateAmounts: build.mutation<UpdateAmountsMutation, UpdateAmountsMutationVariables>({
      query: (variables) => ({ document: UpdateAmountsDocument, variables })
    }),
    CreatePosition: build.mutation<CreatePositionMutation, CreatePositionMutationVariables>({
      query: (variables) => ({ document: CreatePositionDocument, variables })
    }),
    UpdatePosition: build.mutation<UpdatePositionMutation, UpdatePositionMutationVariables>({
      query: (variables) => ({ document: UpdatePositionDocument, variables })
    }),
    SoftDeletePosition: build.mutation<SoftDeletePositionMutation, SoftDeletePositionMutationVariables>({
      query: (variables) => ({ document: SoftDeletePositionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


